<script setup>
import { useStore } from 'vuex';
import { PhxTabs, PhxTabPanel } from '@phx-styleguides/pia';
import { useI18n } from 'vue-i18n';
import { computed, useSlots, watch } from 'vue';
import { TAB_PRODUCTS, getTabComponent, TABS_ORDER } from '../../helpers/search-helper';
import { debounce } from 'lodash-es';
import SearchResultsProductFilters from './SearchResultsProductFilters.vue';
import AlgoliaSearch from '@maison/search/algolia/AlgoliaSearch';
import BoxedLoader from '@maison/components/common/BoxedLoader.vue';

const store = useStore();
const slots = useSlots();
const { t } = useI18n();

const delayForTabSwitch = 300;
const tabs = TABS_ORDER;
const currentTabId = computed(() => getTabId(store.state.search.currentTab));
const filters = computed(() => store.state.search.tabs[TAB_PRODUCTS]?.filters ?? []);
const isLoading = computed(() => store.state.search.isLoading);
const productsCount = computed(() => store.state.search.tabs[TAB_PRODUCTS]?.total ?? 0);
const query = computed(() => store.state.search.query);

const reloadProducts = () => {
  store.dispatch('search/loadData', { clearData: true, tab: TAB_PRODUCTS });
};

const onSortChanged = async (sortOption) => {
  await store.dispatch('search/setSortOption', { tab: TAB_PRODUCTS, sortOption });
  reloadProducts();
};

const onFiltersChanged = async (filters) => {
  await store.dispatch('search/setActiveFilters', { tab: TAB_PRODUCTS, filters });
  reloadProducts();
};

const hasFilters = (tab) => tab === TAB_PRODUCTS && AlgoliaSearch.isEnabled();
const getTabId = (tab) => `search-results-${tab}`;
const getTabLabel = (tab) => `${store.state.search.tabs[tab]?.total} ${t(`Search.tab.${tab}`)}`;
const onTabChange = (tab) => {
  store.dispatch('search/changeCurrentTab', tab);
};

const onLoadFinished = debounce(async (newValue, oldValue) => {
  if (newValue === oldValue || newValue) {
    return;
  }
  const tabs = store.state.search.tabs;
  const tabNames = Object.keys(tabs);
  const firstTab = tabNames.reduce(
    (selectedTab, tab) => (tabs[selectedTab]?.total >= tabs[tab]?.total ? selectedTab : tab),
    [tabNames[0]]
  );
  const firstTabId = getTabId(firstTab);

  if (firstTabId !== currentTabId.value) {
    await store.dispatch('search/changeCurrentTab', firstTabId);
  }
}, delayForTabSwitch);

watch(isLoading, (newValue, oldValue) => onLoadFinished(newValue, oldValue));
</script>

<template>
  <div class="filtered-search-results">
    <phx-tabs
      v-cloak
      v-show="!isLoading"
      id="search-panel-tabs"
      :value="currentTabId"
      theme="light"
      layout="start"
      @change="onTabChange"
    >
      <phx-tab-panel
        v-for="tab in tabs"
        :id="getTabId(tab)"
        :key="tab"
        :name="tab"
        :label="getTabLabel(tab)"
        class="tab-panel--full"
      >
        <search-results-product-filters
          v-if="hasFilters(tab)"
          :products-count="productsCount"
          :facets="filters"
          :query="query"
          @on-filters-changed="onFiltersChanged"
          @on-sort-changed="onSortChanged"
        />
        <div class="filtered-search-results__tab-content">
          <component :is="getTabComponent(tab)" />
          <section v-if="slots.contacts" class="default-search-results__section search-link-list">
            <h2 class="search-link-list__title">{{ t('SearchPanel.contacts.title') }}</h2>
            <ul
              class="list list--stacked list--md list--divided link-list link-list--tight link-list--columns"
              style="--columns-count: 2"
            >
              <slot name="contacts" />
            </ul>
          </section>
        </div>
      </phx-tab-panel>
    </phx-tabs>
    <boxed-loader v-show="isLoading" />
  </div>
</template>
