import { defineAsyncComponent } from 'vue';
import CurrentCountry from '@phoenix/components/CurrentCountry';
import Bag from '@phoenix/components/Bag';
import GeoLoc from '@maison/components/GeoLoc';
import * as Toolkit from '@maison/Toolkit';
import { CURRENT_COUNTRY_CHANGED, CURRENT_USER_IS_LOGGED, emitter } from '@phoenix/event-bus';
import createVueApp from '@phoenix/createVueApp';
import SearchPanel from '../components/search/SearchPanel.vue';

if (document.getElementById('phoenix-account-panel')) {
  const app = createVueApp({}, true);
  app.component(
    'AccountPanel',
    defineAsyncComponent(() => import('../components/myAccount/nav/AccountPanel.vue'))
  );
  app.mount('#phoenix-account-panel');
}

document.querySelectorAll('.phoenix-cart-badge').forEach((el) => {
  const app = createVueApp({}, true);
  app.component(
    'Badge',
    defineAsyncComponent(() => import('../components/checkout/summary/Badge.vue'))
  );
  app.mount(el);
});

document.querySelectorAll('.phoenix-wishlist-badge').forEach((el) => {
  const app = createVueApp({}, true);
  app.component(
    'BadgeWishlist',
    defineAsyncComponent(() => import('../components/checkout/summary/BadgeWishlist.vue'))
  );
  app.mount(el);
});

if (document.getElementById('phoenix-cart-panel')) {
  const app = createVueApp({}, true);
  app.component(
    'CartPanel',
    defineAsyncComponent(() => import('../components/checkout/summary/CartPanel.vue'))
  );
  app.mount('#phoenix-cart-panel');
}

if (document.getElementById('phoenix-wishlist-panel')) {
  const app = createVueApp({}, true);
  app.component(
    'WishlistPanel',
    defineAsyncComponent(() => import('../components/wishlist/WishlistPanel.vue'))
  );
  app.mount('#phoenix-wishlist-panel');
}

if (document.getElementById('phoenix-wishlist-content')) {
  const app = createVueApp({}, true);
  app.component(
    'WishlistContent',
    defineAsyncComponent(() => import('../components/wishlist/WishlistContent.vue'))
  );
  app.mount('#phoenix-wishlist-content');
}

if (document.getElementById('phoenix-search-panel')) {
  const app = createVueApp({}, true);
  app.component('SearchPanel', SearchPanel);
  app.mount('#phoenix-search-panel');
}

const currentCountry = new CurrentCountry();
new GeoLoc(currentCountry);

Object.assign(window, {
  Bag,
  currentCountry,
  GeoLoc,
});

document.querySelectorAll('.drawer-localisation').forEach((el) => {
  el.addEventListener('click', () => {
    emitter.on(CURRENT_COUNTRY_CHANGED, () => window.location.reload());
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('#header');
  if (header) {
    new Toolkit.Header(header);
  }
});

const drawerMyAccountText = document.querySelector('[data-my-account-drawer="1"] span.drawer__link--text');
if (drawerMyAccountText) {
  emitter.on(CURRENT_USER_IS_LOGGED, () => {
    drawerMyAccountText.innerHTML = window.Phoenix.i18n.t('MyAccount.myAccount');
  });
}
