<script setup>
import MixedGridFilters from '../mixedGrid/MixedGridFilters.vue';
import { DEFAULT_SORT } from '@phoenix/helpers/mixed-grid-helper';
import uniqueId from 'lodash-es/uniqueId';
import { computed, toRef, ref, watch } from 'vue';

const props = defineProps({
  facets: {
    type: Array,
    required: true,
  },
  productsCount: {
    type: Number,
    default: 0,
  },
  query: {
    type: String,
    default: '',
  },
});

const facets = toRef(props, 'facets');
const filtersInitialized = ref(false);
const mixedGridFilters = ref(null);
const defaultSort = DEFAULT_SORT;
const id = uniqueId('search-results-products');
const sellableFilterId = `sellable--${window.Phoenix.currentCountry}`;
const shouldDisplaySellable = computed(
  () => Array.isArray(facets.value) && facets.value.find((filter) => filter.id === sellableFilterId) !== undefined
);
const filters = computed(() =>
  Array.isArray(facets.value) ? facets.value.filter((filter) => filter.id !== sellableFilterId) : []
);

const emit = defineEmits(['onSortChanged', 'onFiltersChanged']);

const onFiltersInitialized = () => {
  filtersInitialized.value = true;
};

watch(
  () => props.query,
  () => {
    if (props.query && filtersInitialized.value && mixedGridFilters.value) {
      mixedGridFilters.value.removeAllFilters();
    }
  }
);
</script>

<template>
  <mixed-grid-filters
    ref="mixedGridFilters"
    :sellable-filter-id="sellableFilterId"
    :default-sort="defaultSort"
    :filters="filters"
    :unique-id="id"
    :is-sticky="false"
    :products-count="productsCount"
    :should-display-products-count="false"
    :should-display-sellable="shouldDisplaySellable"
    :large="false"
    panel-id="search-results-products-filters"
    tight
    has-filters-in-url
    :should-whitelist-filters="false"
    url-param-prefix="search"
    @on-sort-change="emit('onSortChanged', $event)"
    @on-filters-changed="emit('onFiltersChanged', $event)"
    @filters-initialized="onFiltersInitialized"
  ></mixed-grid-filters>
</template>
