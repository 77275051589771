import { reactive, toRef } from 'vue';

const store = reactive({});
const limitTerms = 5;

export const useRecentTerms = (namespace = null) => {
  const key = namespace ? `recentTerms.${namespace}` : 'recentTerms';
  if (!store.hasOwnProperty(key)) {
    store[key] = retrieveValue(key);
  }
  const recentTerms = toRef(() => store[key]);

  const addRecentTerm = (term: string) => {
    const sanitizedTerm = term.trim();

    if (!sanitizedTerm || store[key].includes(sanitizedTerm)) {
      return;
    }

    store[key].unshift(sanitizedTerm);

    if (store[key].length > limitTerms) {
      store[key].length = limitTerms;
    }

    storeValue(key, store[key]);
  };

  const clearRecentTerms = () => {
    store[key] = [];
    localStorage.removeItem(key);
  };

  return { recentTerms, addRecentTerm, clearRecentTerms };
};

const retrieveValue = (key: string) => JSON.parse(localStorage.getItem(key)) ?? [];
const storeValue = (key: string, value: string[]) => localStorage.setItem(key, JSON.stringify(value));
